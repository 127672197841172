<template>
    <el-dialog
        v-model="agentInfoShow"
        title='Agent Info'
        width="30%"
    >
        <el-form v-loading=loading size="small">
            <el-form-item :label="lang['html.el-form-item.label.leader']">
                <span>{{info.leader}}</span>
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.username']">
                <span>{{info.username}}</span>
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.password']">
                <el-input v-model="submitData.password" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.name']">
                <el-input v-model="submitData.name" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.nickname']">
                <el-input v-model="submitData.nickname" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.contact']">
                <el-input v-model="submitData.contact" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.status']">
                <el-radio-group v-model="submitData.status">
                    <el-radio :label="1">{{lang['html.el-radio.1']}}</el-radio>
                    <el-radio :label="0">{{lang['html.el-radio.0']}}</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.time']">
            <span>
                <el-tag>
                    {{lang['html.el-form-item.label.login_time']}} {{info.login_time}}
                </el-tag>
                <el-tag style="margin-left: 10px" type="info">
                    {{lang['html.el-form-item.label.reg_time']}} {{info.reg_time}}
                </el-tag>
            </span>
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.money_reg']">
                {{info.money_reg}}
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.money_rec']">
                {{info.money_rec}}
            </el-form-item>

        </el-form>
        <template #footer>
            <el-button type="primary" @click="submit">{{lang['html.el-button.submit']}}</el-button>
            <el-button type="warning" @click="setShow(false)">{{lang['html.el-button.return']}}</el-button>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "agentList-info",
    emits:['updateTable'],
    data(){
        return {
            agentId:0,
            info:{},
            agentInfoShow:false,
            loading:false,
            submitData:{},
            lang:this.$lang.getLang('agent','agentList-info')
        }
    },
    methods:{
        setShow(boolean,agentId){
            this.agentInfoShow = boolean;
            this.agentId = agentId;
            if (boolean){
                this.getAgentInfo();
            }
        },
        getAgentInfo(){
            this.clearInfo();
            if (this.agentId <=0){
                return;
            }
            this.loading = true;
            this.$api.post('Agent.AgentData/getAgentData',{agentId:this.agentId}).then((res)=>{
                this.loading = false;
                let data = this.$helper.checkRes(res);
                this.info = data ? data.data : {};
                this.submitData = {
                    password:this.info.password,
                    nickname:this.info.nickname,
                    contact:this.info.contact,
                    name:this.info.name,
                    status:this.info.status,
                };
            });
        },
        submit(){
            let data = this.submitData;
            //检查是否有修改
            let bool = false;
            for (let dataKey in data) {
                if (data[dataKey] !== this.info[dataKey]){
                    // console.log(dataKey,data[dataKey], this.info[dataKey])
                    bool = true;
                }
            }
            if (!bool){
                this.$alert(this.lang['submit.tips']);
                return;
            }
            this.$message(this.lang['submit.wait']);
            this.loading = true;
            this.$api.post('Agent.AgentData/setAgentInfo',{
                agentId:this.agentId,
                setInfo:data
            }).then((res)=>{
                let data = this.$helper.checkRes(res);
                if(data){
                    this.$alert(this.lang['submit.successful']);
                    //this.getAgentInfo();//重新获取代理商个人信息
                    //修改信息成功,通知父组件强制更新表格数据
                    this.$emit('updateTable',{});
                }else{
                    let code = this.$helper.getResErrorCode(res);
                    let msg = code == 2 ? this.lang['submit.res.code.2'] : this.lang['submit.fail'];
                    this.$alert(
                        msg + '<br><br>Attch:' + res.data.attch ?? '',
                        'errorCode:' + code,
                        {dangerouslyUseHTMLString:true}
                    );
                }
                this.loading = false;
            }).catch(err=>{
                this.loading = false;
                this.$helper.axiosCatch(err);
            });
        },
        clearInfo(){
            this.info = {};
            this.submitData = {};
        }
    },
}
</script>

<style scoped>

</style>