<template>
    <el-dialog
        v-model="isShow"
        :title="lang['html.el-dialog.title']"
        width="50%"
    >
        <el-form >

            <el-form-item :label="lang['html.el-form-item.label.time']" label-width="140px">
                <el-date-picker
                    v-model="filter.time"
                    type="datetimerange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    value-format="YYYY-MM-DD HH:mm:ss"
                />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.username']" label-width="140px">
                <el-input v-model="filter.username" autocomplete="off" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.name']" label-width="140px">
                <el-input v-model="filter.name" autocomplete="off" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.label.nickname']" label-width="140px">
                <el-input v-model="filter.nickname" autocomplete="off" />
            </el-form-item>

            <span>{{lang['html.span.tips']}}</span>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="submit">{{lang['html.el-button.submit']}}</el-button>
            <el-button type="warning" @click="setShow(false)">{{lang['html.el-button.return']}}</el-button>
            <el-button type="info" @click="clearForm">{{lang['html.el-button.reset']}}</el-button>
        </template>

    </el-dialog>

</template>

<script>
export default {
    name: "agentList-filter",
    props:['tableData'],
    emits:['watchFilter'],
    data() {
        return {
            //筛选条件值
            filter:{
                username:'',
                name:'',
                time:[],
                nickname:''
            },
            //[显示/隐藏]筛选器
            isShow:false,
            loadingHandle:null,
            lang:this.$lang.getLang('agent','agentList-filter')
        }
    },
    methods:{
        //提交筛选
        submit(){
            console.log('submit',this.filter,this.filter.time);
            //检测是否已设置条件
            if (this.filter.time.length !=2 && this.filter.name == '' && this.filter.username == '' && this.filter.nickname ==''){
                this.$alert(this.lang['submit.error.filter']);
                return;
            }
            this.loadingHandle = this.$loading({
                lock: true,
                text: this.lang['submit,wait'],
                background: 'rgba(0, 0, 0, 0.7)',
            });
            this.filterUser();
        },
        //显示/隐藏筛选器
        setShow(bool){
            this.isShow = bool;
        },
        //执行筛选任务
        filterUser(){
            let res = [];//存放筛选后的用户数据
            let num = 0;
            for (let key in this.tableData) {
                let agent = this.tableData[key];
                let bool = this.isFilter(agent);
                if (bool){
                    res.push(agent);
                    num++;
                    if (num>=20000){
                        break;
                    }
                }
            }
            this.loadingHandle.close();
            //传给父组件参数
            this.$emit('watchFilter',res);
            this.clearForm();
        },
        /**
         * 判断用户是否符合检测规则
         * @param agent 用户数据
         * @returns {boolean}
         * 符合返回true
         */
        isFilter(agent){
            let res = this.filter;
            //筛选字符串类型
            let bool = this.isHas(res,agent,'username') || this.isHas(res,agent,'nickname')
                || this.isHas(res,agent,'name');
            if (bool){
                return true;
            }
            //筛选时间类型
            if (res.time.length == 2){
                bool = this.$helper.isTimeFrame(res.time[0],res.time[1],agent.reg_time);
                if (bool){
                    return bool;
                }
            }
            return false;
        },
        /**
         * [筛选器]检查指定元素是否复合规则(字符串)
         * @param filter 筛选规则
         * @param user 用户数据
         * @param key 检查的元素名
         * @returns {boolean}
         */
        isHas(filter,user,key){
            let data = user[key];
            let check = filter[key];
            if (check != ''){
                if (this.$helper.checkExistString(data,check)){
                    return true;
                }
            }
            return false;
        },
        //清空表单数据
        clearForm(){
            this.filter = {
                username:'',
                name:'',
                time:[],
                nickname:''
            };
        }
    }
}
</script>

<style scoped>
</style>